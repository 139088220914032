import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const LISTING_QUERY = graphql`
	query BlogAllListing {
		allMarkdownRemark(skip: 3, sort: { order: DESC, fields: [frontmatter___date] }) {
			edges {
				node {
					fields {
						slug
					}
					excerpt
					frontmatter {
						title
						featuredimage {
                            childImageSharp {
                                gatsbyImageData
                            }
                            relativePath
						}
						date(formatString: "MMMM DD, YYYY")
					}
				}
			}
		}
	}
`
const ListingArticleSmall = () => (
	<StaticQuery
		query={LISTING_QUERY}
		render={({ allMarkdownRemark }) => (
			<>
				{allMarkdownRemark.edges.map(({ node }) => (
					<div key={node.fields.slug} className="col-12 col-md-6 col-lg-3 blog__all-item">
						<article className="article-box article-box--hg">
							<Link to={`/blog${node.fields.slug}/`} className="article-box__img-wrapper">
								<GatsbyImage className={'article-box__img'} 
									alt={'Article'} 
									image={getImage(node.frontmatter.featuredimage)} />								
							</Link>
							<div className="article-box__copy">
								<p className="article-box__subtitle">
									<Link to={`/blog${node.fields.slug}/`}> {node.frontmatter.subtitle}</Link>
								</p>
								<h4 className="h4--lg article-box__title">
									<Link to={`/blog${node.fields.slug}/`}> {node.frontmatter.title}</Link>
								</h4>
								<p className="article-box__text">{node.excerpt}</p>
								<p className="p--md">
									BY<a href='https://www.linkedin.com/in/devin-mallonee/' className="article-box__author">
										{node.slug}
									</a>{' '}
									- Web Development @ Codestaff
								</p>
							</div>
						</article>
					</div>
				))}
			</>
		)}
	/>
);

export default ListingArticleSmall;
         