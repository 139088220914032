import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export const ListingArticleLarge = () => {
	const data = useStaticQuery(graphql`
		query BlogMainListing {
			allMarkdownRemark(limit: 3, sort: { order: DESC, fields: [frontmatter___date] }) {
				edges {
					node {
						fields {
							slug
						}
						excerpt
						frontmatter {
							title
							featuredimage {
								childImageSharp {
									gatsbyImageData
								}
								relativePath
							}
							date(formatString: "MMMM DD, YYYY")
						}
					}
				}
			}
		}
	`);

	return (
		<div className="row">
			{data.allMarkdownRemark.edges.map(({ node }) => {
				return (
					<div key={node.fields.slug} className="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
						<article className="article-box article-box--hg">
						<Link to={`/blog${node.fields.slug}/`} className="article-box__img-wrapper">
							<GatsbyImage className={'article-box__img'} alt={'Article xxxx'} 
                                image={getImage(node.frontmatter.featuredimage)} />
						</Link>      
						<div className="article-box__copy">
							<p className="article-box__subtitle">
								<Link to={`/blog${node.fields.slug}/`}> {node.frontmatter.subtitle}</Link>
							</p>
							<h4 className="h4--lg article-box__title">
								<Link to={`/blog${node.fields.slug}/`}> {node.frontmatter.title}</Link>
							</h4>
							<p className="article-box__text">{node.excerpt}</p>
							<p className="p--md">
								BY<a href="https://www.linkedin.com/in/devin-mallonee/" className="article-box__author">
									{node.slug}
								</a>{' '}
								- Web Development @ Codestaff
							</p>
						</div>                                              
						</article>
					</div>
				);
			})}
		</div>
	);
};
