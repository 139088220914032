import React from 'react'
import Layout from '../components/layout'
import Listing from '../components/listing'
import Posts from '../components/posts'
import Seo from '../components/seo'

const Blog = ({ location }) => {
  return (
    <Layout location={location}>
      {/* These are the actual blog posts from the cms. They come in two components: ListingArticleLarge (3x format) and ListingArticleSmall (4x format).
                The 3x format is a single row of the 3 most recent. Older posts are in multiple rows. */}
      <Listing />
      =====================================================
      {/* These are the hard-coded posts done in react and html in the "FeaturedArticles component */}
      <Posts />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title =
    'Web Development Blog for Freelancers: Tips & Tricks for Programmers'
  const description =
    'Top Tips, Tricks & Newest Web Development Resources.  The Code Staff Web Development Blog is and Educational tool helping freelancers navigate their freelancing journey.'

  return <Seo type="page" title={title} description={description} />
}

export default Blog
