import React from 'react';
import '../css/main.css'
import '../css/main.scss'
import FeaturedArticles from './featured-articles';
import TopTrendingSkills from './top-trending-skills';
import HireAndApply from './hire-and-apply';

//TODO ?
/**
 * A temporary component to be replaced by the method used in
 * /home/dean/projects/gatsby/codestaffio/src/components/listing.js
 * using graphql to pull the markup files from outside the src 
 * directory.
 */
const Posts = () => (
    <main>
        <FeaturedArticles />
        <section className="tags">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="mb-5">EXPLORE IDEAS</h2>
                        <ul className="tags__list">
                            <li>UX</li>
                            <li>Web Design</li>
                            <li>Mobile Design</li>
                            <li>Web Development</li>
                            <li>Photography</li>
                            <li>Video Production and Editing</li>
                            <li>3D</li>
                            <li>Design</li>
                            <li>Audio &amp; Sound Design</li>
                            <li>WordPress</li>
                            <li>Graphic Design</li>
                            <li>Marketing</li>
                            <li>Freelancing</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <HireAndApply />
        <TopTrendingSkills />
    </main>
);

export default Posts;