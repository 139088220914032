import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {ListingArticleLarge} from '../components/listing-article-large';
import ListingArticleSmall from '../components/listing-article-small';

//import SEO from '../components/seo';

const Listing = () => (
	<main>
		<section className="banner banner--alt">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<h1 className="u-color-secondary u-weight-smb">The Codestaff Blog</h1>
						<p className="p--hg u-ls-0 u-color-black-2 mt-3 mt-md-2 mb-5 mb-md-4">
							The Codestaff Blog is the top hub for business, design, and technology talent, executives,
							and entrepreneurs,<br className="d-none d-lg-block" />
							<span className="d-lg-none">&nbsp;</span>featuring key technology updates, tutorials,
							freelancer resources, and management insights.
						</p>
						<ul className="social">
							<li>
								<a href="https://twitter.com/code_staff">
									<FontAwesomeIcon icon={faTwitter} />
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/company/code-staff">
									<FontAwesomeIcon icon={faLinkedin} />
								</a>
							</li>
							<li>
								<a href="https://www.facebook.com/codestaff">
									<FontAwesomeIcon icon={faFacebook} />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<section className="blog">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="blog__main">
							<div className="blog__main-intro">
								<p className="tag nopointer">
									Codestaff Blog
								</p>
								<h2 className="h2--xl u-weight-bld mt-4 mt-md-3 mb-2">Web Development</h2>
								<p className="p--hg u-ls-0 u-color-gray-3">
									Work with hand-picked talent, evaluated with our thorough screening process
								</p>
							</div>
							<div className="blog__main-content">
								<ListingArticleLarge />
							</div>
							<div className="blog__all">
								<div className="blog__all-intro">
									<h2 className="u-uppercase">
										Read the most recent<br />Web Development articles
									</h2>
									<p className="p--hg u-color-gray-3 u-ls-0">
										This space is all about you, from Ruby to PHP!
									</p>
                                    </div>

									<div className="row blog__all-main">
										<ListingArticleSmall />
									</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
);

export default Listing;
